<template>
  <div class="scale-weights flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <date-range
          v-model="filters"
          @input="fetchWeights()"
        />

        <div class="flex flex-column gap-1">
          <div>Scale:</div>
          <button-select
            v-model="filters.device_id"
            :options="options.device_id"
            size="sm"
            @input="fetchWeights()"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="fetchWeights()"
        >
          Refresh
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ weights.length | format }} / {{ weightCount | format }} weight(s)
    </div>

    <table class="table table-google table-weights">
      <thead>
        <tr>
          <th>ID</th>
          <th>Scale</th>
          <th>Date</th>
          <th>Weight</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="8">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="weights.length === 0">No weights found</span>
            </div>
          </td>
        </tr>

        <tr v-for="weight in weights" :key="weight.id">
          <td class="row-fit text-nowrap">{{ weight.id | format }}</td>
          <td class="row-fit text-nowrap">{{ weight.device_description }}</td>
          <td class="row-fit text-nowrap">
            {{ weight.weight_ts | longdate }} {{ weight.weight_ts | time }}
          </td>
          <td class="text-nowrap">{{ weight.weight_value | round }} g</td>
          <td>
            <div class="d-flex gap-2 text-nowrap">
              <div>
                <b-badge
                  :variant="weightTypeBadge[weight.weight_type]"
                >
                  {{ weight.weight_type | capitalize }}
                </b-badge>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="weightCount"
      :per-page="filters.per_page"
      @input="fetchWeights(true)"
      :disabled="isLoading"
    ></b-pagination>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const DateRange = () => import('@/components/invoices/DateRange.vue');

export default {
  name: 'ScaleWeights',
  components: {
    ButtonSelect,
    DateRange,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      weights: [],
      weightCount: 0,
      devices: [],
      filters: {
        date_from: null,
        date_to: null,
        device_id: null,
        page: 1,
        per_page: 500,
      },
      options: {
        device_id: [],
      },
      weightTypeBadge: {
        stable: 'primary',
        unstable: 'light',
        error: 'danger',
      },
    };
  },
  methods: {
    fetchDevices() {
      this.loadingCount++;
      this.$http
        .get('/scale/devices')
        .then((res) => {
          const deviceOptions = [
            { value: null, text: 'All' },
          ];
          res.body.devices.forEach((device) => {
            deviceOptions.push({ value: device.id, text: device.device_description });
          });
          this.options.device_id = deviceOptions;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch devices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchWeightData() {
      this.loadingCount++;
      this.$http
        .get('/scale/weights')
        .query(this.filters)
        .then((res) => {
          this.weights = res.body.weights;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch weights: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchWeightCount() {
      this.loadingCount++;
      this.$http
        .get('/scale/weights_count')
        .query(this.filters)
        .then((res) => {
          this.weightCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch weight count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchWeights(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchWeightCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchWeightData();
    },
  },
  created() {
    this.fetchDevices();
    this.fetchWeights();
  },
};
</script>

<style lang="scss" scoped>
  .scale-weights {
    max-width: 600px;
  }

  .table-weights .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }
</style>
